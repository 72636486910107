body {
  background-color: #f2f2f2;
  background-image: url('./images/bg-black-stone.jpeg');
  background-position: initial;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: '100%';
}

#root {
  min-height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}